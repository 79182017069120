@import "_variables.scss";

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $spacer-half;
  font-weight: 600;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: $spacer;
  line-height: 1.5;
}

a {
  color: $primary-color;

  &:hover {
    color: darken($primary-color, 10%);
  }
}

ul, ol {
  margin-top: 0;
  margin-bottom: $spacer;
  padding-left: 1.25rem;

  li {
    margin-bottom: $spacer-half;
  }
}

blockquote {
  margin-top: 0;
  margin-bottom: $spacer;
  padding: $spacer-half;
  font-style: italic;
  border-left: 4px solid $border-color;
  color: $text-primary-color;
  background-color: $light-color;
}
