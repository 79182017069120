@import "src/variables";
@import "src/mixins";

.section-contact {
  width: 100%;

  .section-wrapper {
    width: 100%;
    max-width: 1500px;
    padding: 60px 5vw;
    margin-left: auto;
    margin-right: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @include media-breakpoint-down($breakpoint-lg) {
      grid-template-columns: 1fr;
      padding: 60px 0;
    }

    .column-financing {
      grid-row: 1;
      grid-column: 1;

      padding: 80px 54px;
      background-color: $primary-color;

      @include media-breakpoint-down($breakpoint-lg) {
        grid-row: 1;
        grid-column: 1;
        padding: 60px 54px;
      }

      .title {
        width: 100%;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
      }

      .text {
        margin-top: 30px;
        font-size: 1.1rem;
      }
    }

    .column-contact {
      grid-row: 1;
      grid-column: 2;

      padding: 80px 54px;
      background-color: $primary-color;

      @include media-breakpoint-down($breakpoint-lg) {
        grid-row: 2;
        grid-column: 1;
        padding: 60px 54px;
      }

      .title {
        width: 100%;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
      }

      .name {
        margin-top: 60px;
        margin-left: 90px;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;

        @include media-breakpoint-down($breakpoint-lg) {
          margin-left: 60px;
        }
      }

      .row-email {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;

        .image {
          width: 50px;
          height: 50px;
        }
        .text {
          margin-left: 40px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          white-space: pre-line;

          @include media-breakpoint-down($breakpoint-lg) {
            margin-left: 10px;
          }
        }
      }

      .row-phone {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;

        .image {
          width: 50px;
          height: 50px;
        }
        .text {
          margin-left: 40px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          white-space: pre-line;

          @include media-breakpoint-down($breakpoint-lg) {
            margin-left: 10px;
          }
        }
      }

    }
  }


}
