@import "src/variables";
@import "src/mixins";

.section-about {
  max-width: 1600px;
  padding-top: 124px;
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down($breakpoint-lg) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title {
    //width: 50%;
    padding-bottom: 54px;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    padding-left: 5vw;
    padding-right: 5vw;

    @include media-breakpoint-down($breakpoint-xl) {
      grid-template-columns: 1fr;
    }

    .text-wrap {
      padding-left: 20px;
      padding-right: 20px;

      grid-row: 1;
      grid-column: 1;

      @include media-breakpoint-down($breakpoint-xl) {
        grid-row: 2;
        grid-column: 1;
      }

      white-space: pre-line;

      .text {
        font-size: 1.1rem;
      }
    }
    .image-wrap {
      padding-left: 20px;
      padding-right: 20px;

      grid-row: 1;
      grid-column: 2;

      @include media-breakpoint-down($breakpoint-xl) {
        grid-row: 1;
        grid-column: 1;
        margin-bottom: 40px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}