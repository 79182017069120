// Colors
$primary-color: #D9D9D9;
$secondary-color: #EFEFEF;

$text-primary-color: #000000;
$text-secondary-color: #212529;

$background-color: #fff;
$border-color: #ced4da;

$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;

$light-color: #f8f9fa;
$dark-color: #343a40;

// Spacing
$spacer: 1rem;
$spacer-half: $spacer / 2;
$spacer-two-thirds: $spacer * 2 / 3;
$spacer-three-quarters: $spacer * 3 / 4;

// Heights
$header-height: 68px;
$divider-height: 68px;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-monospace: 'Source Code Pro', monospace;
