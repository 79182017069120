@import "src/variables";
@import "src/mixins";

.section-plan {
  padding-top: 124px;
  padding-bottom: 100px;
  background-color: $secondary-color;

  @include media-breakpoint-down($breakpoint-lg) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .content {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;

    //margin-left: 10vw;
    //margin-right: 10vw;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
    .image {
      width: 100%;
      padding: 0 6%;
      height: auto;
      margin-top: 100px;

      @include media-breakpoint-down($breakpoint-lg) {
        margin-top: 60px;
      }
    }
  }

}
