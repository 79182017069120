@import "src/variables";
@import "src/mixins";
@import "src/typography";

html {
  font-size: calc(10px + .4vmin);
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;

  background-color: $background-color;
  color: $text-primary-color;

  font-family: "Roboto", "Ubuntu", sans-serif;
  letter-spacing: 0.01em;

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

}
