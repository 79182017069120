@import "src/variables";
@import "src/mixins";

.page {
  width: 100%;
  height: calc(100vh - #{$header-height});

  margin-top: #{$header-height};

  overflow-y: auto;
}

.page-content {
  //@include flex-center-column();
}
