@import "src/variables";
@import "src/mixins";

.section-sponsors {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 140px;
  background-color: $primary-color;

  @include media-breakpoint-down($breakpoint-lg) {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .content {

    .title {
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
    }

    .images {
      margin-top: 100px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;

      .image {
        width: auto;
        height: 60px;
      }

      @include media-breakpoint-down($breakpoint-lg) {
        margin-top: 40px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .image {
          margin: 40px 0;
          max-width: 75%;
          max-height: 80px;
          height: auto;
        }
      }
    }
  }
}
