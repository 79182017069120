@import "../../mixins.scss";

.index {
  height: 100%;

  //display: flex;
  //align-items: center;
  //justify-content: center;
  //flex-direction: column;
}
