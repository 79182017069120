@import "src/variables";
@import "src/mixins";

.section-home {
  height: 100%;

  .top {
    height: calc(100% - #{$divider-height});
    display: grid;

    .background {
      height: 100%;
      width: 100%;
      position: relative;
      right: 0;
      top: 0;
      grid-row: 1;
      grid-column: 1;

      background-image: url(/assets/background.jpeg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: relative;
      right: 0;
      top: 0;
      grid-row: 1;
      grid-column: 1;

      background: $primary-color;
      opacity: 50%;
    }

    .content {
      height: 100%;
      width: 100%;
      position: relative;
      right: 0;
      top: 0;
      grid-row: 1;
      grid-column: 1;

      display: flex;
      align-items: center;
      justify-content: right;

      .title {
        margin-bottom: 8%;
        margin-right: 8%;

        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        text-transform: uppercase;
        white-space: pre-line;
      }

      @include media-breakpoint-down($breakpoint-lg) {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
          margin: 8% 8% 30%;

          font-style: normal;
          font-weight: 600;
          font-size: 2.6rem;
          line-height: 3rem;
          text-align: center;
        }
      }
    }
  }

  .divider {
    height: $divider-height;

    background: $primary-color;
  }

}
