@import "src/variables";
@import "src/mixins";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 20px;
  background-color: $primary-color;
  z-index: 1;

  @include media-breakpoint-down($breakpoint-lg) {
    justify-content: center;
  }

  .nav-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .logo {
      width: 200px;
      height: 50px;
      margin-left: 40px;
      pointer-events: none;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .nav-right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;
      margin-right: 70px;
      font-size: 16px;
      color: $text-primary-color;
      cursor: pointer;
      text-transform: uppercase;
      user-select: none;
      font-weight: 400;

      transition: all .1s ease-in-out;

      &.active {
        transform: scale(1.04);
      }
      &:hover {
        //transform: scale(1.04);
      }
    }

    @include media-breakpoint-down($breakpoint-lg) {
      display: none;
    }
  }
}
